<template>
  <div
    class="popup_wrap"
    style="width:1100px;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      type="button"
      class="layer_close"
      @click.prevent="$emit('close', 'X')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('common.UNNO_SEARCH.001') }}
      </h1> <!-- UNNO 검색결과 -->
      <form id="unnoPopForm" action="return false;" @submit.prevent>
        <!-- search_box -->
        <div class="content_box">
          <div class="clearfix">
            <span class="tit_label">{{ $t('msg.SCHD010P020.001') }}</span> <!-- UNNO -->
            <input
              ref="inputUnno"
              id="unno"
              v-model="value"
              type="text"
              style="width:200px;"
              @keypress="unnoEnter"
            >&nbsp;
            <a
              class="button blue sh"
              @click="searchUnno()"
            >{{ $t('msg.CMBA100.00120') }}</a> <!-- 검색 -->
            <span style="float:right;">{{localTime}}</span>
          </div>
        </div>
        <!-- content_box -->
      </form>
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.SCHD010P020.002') }} : <span class="num">{{ items.length }}</span></span> <!-- Total : n -->
          <span class="ml_auto cal_btn"><a class="button sm" @click="excelDownload">
            {{ $t('msg.CSCT060G040.005') }}</a></span>  <!-- 엑셀 다운로드 -->
        </div>

        <div class="mt10 text_right">
        </div>

        <div style="overflow-y:auto; max-height: 500px;">
          <table class="tbl_col">
            <colgroup>
              <col width="10%;">
              <col width="5%;">
              <col width="15%;">
              <col width="27%;">
              <col width="27%;">
              <col>
            </colgroup>
            <thead>
              <tr>
                <th>Unno.</th>
                <th>Class</th>
                <th>Name</th>
                <th>{{ $t('msg.SCHD010P020.006') }} </th> <!-- 선적불가선사 -->
                <th>{{ $t('msg.SCHD010P020.007') }} </th> <!-- 선적불가포트 -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in items"
                :key="index"
              >
                <td class="t-center" v-html="item.imdunNo" />
                <td v-html="item.class" class="t-center" />
                <td v-html="item.dsc" style="text-align:left; vertical-align:text-top;"  />
                <td v-html="item.mvmtSpcoCstNm" class="t-left" />
                <td v-html="item.portNm"  class="t-left" />
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div id="unno-realgrid" style="width: 100%; height: 450px">
        </div> -->
      </div>
      <!-- content_box // -->
    </div>
  </div>
</template>

<script>

//메모
//unno : 운송 위험 및 유해성이 있는 화학 물질에 부여된 번호
//class : 위험물의 급수(class1 ~ class9)
//imgd  : 국제해상위험물규정
//PG : Package Grade (포장등급) / I : 고위험성, II : 중위험성, III : 저위험성
//Proper Shipping Name : 정식 운송명

import unno from '@/api/rest/trans/unno'
import { GridView, LocalDataProvider } from 'realgrid' // realgrid Component
import JSZip from 'jszip' // 엑셀 다운로드용 JSZip Component
import moment from 'moment' // 날짜 가져오는 Component
import XLSX from 'xlsx'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'dsc', dataType: 'text' },
  { fieldName: 'imdgSeq', dataType: 'text' },
  { fieldName: 'imdunNo', dataType: 'text' },
  { fieldName: 'imoClasCd', dataType: 'text' },
  { fieldName: 'mvmtSpcoCstNm', dataType: 'text' },
  { fieldName: 'portNm', dataType: 'text' }
]

export const columns = [
  { name: 'dsc', fieldName: 'dsc', type: 'text', header: { text: 'UN No.' }, editable: false, width: 80 }, //
  { name: 'imdgSeq', fieldName: 'imdgSeq', type: 'text', header: { text: 'Class' }, editable: false, width: 80 }, //
  { name: 'imdunNo', fieldName: 'imdunNo', type: 'text', header: { text: 'Name' }, editable: false, width: 80 }, //
  { name: 'imoClasCd', fieldName: 'imoClasCd', type: 'text', header: { text: '선적불가선사' }, editable: false, width: 80 }, //
  { name: 'mvmtSpcoCstNm', fieldName: 'mvmtSpcoCstNm', type: 'text', header: { text: '선적불가선사' }, editable: false, width: 80 }, //
  { name: 'portNm', fieldName: 'portNm', type: 'text', header: { text: '선적불가포트' }, editable: false, width: 80 } //
]

const gridLayout = [
  'dsc', //
  'imdgSeq', //
  'imdunNo', //
  'mvmtSpcoCstNm', //
  'portNm' //
]

export default {
  name: 'UnnoSearchPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          unno: '',
          selectIdx: '',
          containerId: '', // old : callbackID
          unnoId: '', // old : callbackID2
          uiFlag: '',
          scheduleFlag: 'N',
          cntrTypCd: ''
        }
      }
    }
  },
  data () {
    return {
      value: this.parentInfo.unno,
      fixedValue: '',
      emsFeCdList: [],
      emsSeCdList: [],
      items: [],
      scheduleFlag: this.parentInfo.scheduleFlag,
      total: 0,
      localTime: ''
    }
  },
  created () {
    this.initSelect()
    this.pageInit()
  },
  mounted: function () {
    console.log('mounted')
    this.$nextTick(() => {
      this.$refs.inputUnno && this.$refs.inputUnno.focus()
    })

    // this.gridInit()
  },
  methods: {
    gridInit () {
        //엑셀 다운로드용 JSZip 셋팅
        window.JSZip = window.JSZip || JSZip

        provider = new LocalDataProvider(true)
        gridView = new GridView('unno-realgrid')

        // 그리드 필드 생성
        provider.setFields(fields)

        // columns 명
        gridView.setColumns(columns)
        gridView.setColumnLayout(gridLayout)
        gridView.setDataSource(provider)

        // 그리고 헤더 높이 조정
        gridView.header.heights = [40]
        gridView.displayOptions.fitStyle = 'evenFill'
        // gridView.displayOptions.selectionStyle = 'block'
        // gridView.displayOptions.rowHeight = -1
        gridView.displayOptions.syncGridHeight = 'always'
        // gridView.displayOptions.minRowHeight = 40

        gridView.setStateBar({
          visible: false
        })
        gridView.setRowIndicator({
          visible: false
        })
        gridView.setCheckBar({
        visible: false
        })
    },
    pageInit () {
      console.log('this.parentInfo.unno ===> ', this.parentInfo.unno)
console.log('this ===> ', this)
      this.value = this.parentInfo.unno
      //this.fixedValue = this.parentInfo.unno

      const $vm = this

      //API 호출 메소드

      if (this.$ekmtcCommon.isNotEmpty(this.value)) {
        $vm.searchUnno()
      }
    },
    initSelect () {
      for (let i = 65; i <= 74; i++) { // 대문자 A-J
        this.emsFeCdList.push(String.fromCharCode(i))
      }

      for (let i = 65; i <= 90; i++) { // 대문자 A-Z
        this.emsSeCdList.push(String.fromCharCode(i))
      }
    },
    unnoEnter (e) {
      if (e.keyCode === 13) {
        this.searchUnno()
      }
    },
    //기본 API 검색 메소드
    searchUnno () {
      //입력값 없는 경우 벨리데이션 처리
      if (this.$ekmtcCommon.isNotEmpty(this.value)) {
        const arrCheckUnno = ['2211', '3314', '1013']

        if (this.parentInfo.scheduleFlag !== 'Y' && arrCheckUnno.indexOf(this.value) === -1 && this.parentInfo.cntrTypCd === 'OT') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.391'))
          return
        }
        this.fixedValue = this.value
        this.items = []
        //api call
        unno.getDgUnno(this.value).then((data) => {
          const list = this.arrangingData(data.data)
          this.localTime = this.getToday()
          //셋팅할 데이터 to array
          list.forEach((item) => {
            // item.tchlNm = ''
            this.items.push(item)
            // provider.setRows(this.items)
            // this.total = this.items.length
          })
        })

        console.log('unno this.items @@@@ ', this.items)

        this.$nextTick(() => {
          const div = document.querySelector('.unno_pop')
          const selector = div.querySelector('#unno')

          this.$ekmtcCommon.hideErrorTooltip(selector)
        })
      } else {
        this.$nextTick(() => {
          const div = document.querySelector('.unno_pop')
          const selector = div.querySelector('#unno')

          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.SCHD010P020.003'))
        })
      }
    },
    //화면에 텍스트 등 가공해서 보여주기 위한 작업 메소드
    arrangingData (list) {
      const arrangedList = list.map((data) => {
        //class arrange
        let classStr = ''
        classStr += data.sbRiskVal1 !== '' && data.sbRiskVal1 !== undefined ? '<br/>' + data.sbRiskVal1 : ''
        classStr += data.sbRiskVal2 !== '' && data.sbRiskVal2 !== undefined ? '<br/>' + data.sbRiskVal2 : ''
        classStr += data.sbRiskVal3 !== '' && data.sbRiskVal3 !== undefined ? '<br/>' + data.sbRiskVal3 : ''
        data.class = data.imoClasCd + classStr

        data.mvmtSpcoCstNm = data.mvmtSpcoCstNm !== '' && data.mvmtSpcoCstNm !== undefined ? data.mvmtSpcoCstNm.replaceAll('\r', '<br/>') : ''
        data.portNm = data.portNm !== '' && data.portNm !== undefined ? data.portNm.replaceAll('\r', '<br/>') : ''

        return data
      })
      return arrangedList
    },
    //Unno 선택시 화면에 input할 위험물 Outer Type 리스트 가져오기
    async fnDgOuterSet (formData) {
      let dgOuterList = []
      await unno.getDgOuterList(formData).then((data) => {
        dgOuterList = data.data
      })

      return dgOuterList
    },
    //UNNO 선택시 화면에 input할 위험물 Additional Information 제목
    async fnDgAddInfoMsg (formData) {
      let addInfoMsgList = []
      await unno.getDgAddInfoMsg(formData).then((data) => {
        addInfoMsgList = data.data
      })

      return addInfoMsgList
    },
    // old : fnClassIDSelect
    async fnSetUnno (item) {
      let techName = item.tchlNm
      //HS가 뭔지 파악 필요
      if (techName !== undefined) {
        item.tchlNm = techName.trim()
      }

      const arrCheckUnno = ['2211', '3314']
      if (this.parentInfo.scheduleFlag !== 'Y' && arrCheckUnno.indexOf(item.imdunNo) > -1 && this.parentInfo.cntrTypCd !== 'OT') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.390'))
        return
      }

      if ((item.tchlNmMdtyYn === 'Y' || item.mpltCd === 'Y') && this.$ekmtcCommon.isEmpty(item.tchlNm)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.SCHD010P020.004')) //Please input compulsory data Technical name contributing to Marine Pollutant.
        return
      }

      if (this.$ekmtcCommon.isEmpty(item.mpltCd)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.SCHD010P020.005')) //Please Select compulsory data Marine Pollutant
        return
      }

      const cntrTypCd = this.parentInfo.cntrTypCd
      //opener화면의 Outer Type을 동적으로 그려줌(외장 타입?)
      //cntrTypeCd는 opener에서 가져옴
      const dgOuterList = await this.fnDgOuterSet({ imdunNo: item.imdunNo, imdgSeq: item.imdgSeq, cntrTypeCd: cntrTypCd })
      //opener화면의 Additional Information을 동적으로 그려줌
      const addInfoMsg = await this.fnDgAddInfoMsg({ imdunNo: item.imdunNo, imdgSeq: item.imdgSeq })

      item.dgOuterList = dgOuterList
      item.addInfoMsg = addInfoMsg
      item.selectIdx = this.parentInfo.selectIdx
      this.$emit('selectFunc', item)
      this.$emit('close')
    },
    excelDownload () {
      let excelData = []

      for (const items of this.items) {
        const excelLogs = {
          UN_No: items.imdunNo,
          Class: items.class.replaceAll('<br/>', '\r\n'),
          Name: items.dsc,
          CstNm: items.mvmtSpcoCstNm.replaceAll('<br/>', '\r\n'),
          portNm: items.portNm.replaceAll('<br/>', '\r\n')
        }

        excelData.push(excelLogs)
      }

      const workSheet = XLSX.utils.json_to_sheet(excelData)
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, workSheet, 'unno')
      XLSX.writeFile(workBook, 'unno.xlsx')
    },
    getToday () {
      let today = new Date()
      let year = today.getFullYear() //연도
      let month = ('0' + (today.getMonth() + 1)).slice(-2) //월
      let date = ('0' + today.getDate()).slice(-2) //날짜
      let hour = ('0' + today.getHours()).slice(-2) //시간
      let time = ('0' + today.getMinutes()).slice(-2) //시간

      if (this.serviceLang() === 'KOR') {
        return year + '년 ' + month + '월 ' + date + '일 ' + hour + '시 ' + time + '분 기준'
      } else {
        return this.changeDateTime2Display(year + month + date + hour + time, this.serviceLang())
      }
    },
    serviceLang () {
      return this.$store.getters['auth/serviceLang']
    },
    changeDateTime2Display (strDate, lang) {
      var rtnStrDate = ''
      if (strDate === null || strDate === '') {
        rtnStrDate = ''
      } else {
        if (lang === 'ENG') {
          if (strDate.substring(4, 6) === '01') {
            rtnStrDate = strDate.substring(0, 4) + '.Jan.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '02') {
            rtnStrDate = strDate.substring(0, 4) + '.Feb.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '03') {
            rtnStrDate = strDate.substring(0, 4) + '.Mar.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '04') {
            rtnStrDate = strDate.substring(0, 4) + '.Apr.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '05') {
            rtnStrDate = strDate.substring(0, 4) + '.May.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '06') {
            rtnStrDate = strDate.substring(0, 4) + '.Jun.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '07') {
            rtnStrDate = strDate.substring(0, 4) + '.Jul.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '08') {
            rtnStrDate = strDate.substring(0, 4) + '.Aug.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '09') {
            rtnStrDate = strDate.substring(0, 4) + '.Sep.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '10') {
            rtnStrDate = strDate.substring(0, 4) + '.Oct.' + strDate.substring(6, 8)
          } else if (strDate.substring(4, 6) === '11') {
            rtnStrDate = strDate.substring(0, 4) + '.Nov.' + strDate.substring(6, 8)
          } else {
            rtnStrDate = strDate.substring(0, 4) + '.Dec.' + strDate.substring(6, 8)
          }
        } else {
          rtnStrDate = strDate.substring(0, 4) + '.' + strDate.substring(4, 6) + '.' + strDate.substring(6, 8)
        }
        rtnStrDate = rtnStrDate + ' ' + strDate.substring(8, 10) + ':' + strDate.substring(10, 12)
      }
      return rtnStrDate
    }
  }
}
</script>

<style scoped>
.t-center {text-align:center; vertical-align:text-top;}
.t-left   {text-align:left; vertical-align:text-top; color: red;}
</style>
